<template>
  <div>
    <b-container>
      <div v-if="loading">
        <div class="loader text-center"></div>
      </div>
      <div v-else>
        <div v-if="detail.status === 'cancel'" class="swal2-icon swal2-error swal2-icon-show"
             style="display: flex;">
              <span class="swal2-x-mark">
              <span class="swal2-x-mark-line-left"></span>
              <span class="swal2-x-mark-line-right"></span>
              </span>
        </div>
        <div v-if="detail.status === 'success'" class="swal2-icon swal2-success swal2-icon-show"
             style="display: flex;">
          <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
          <span class="swal2-success-line-tip"></span> <span class="swal2-success-line-long"></span>
          <div class="swal2-success-ring"></div>
          <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
          <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
        </div>
        <div class="text-center">
          <p>ID: <strong>{{ detail.token }}</strong></p>
          <p>Merchant: <strong>{{ detail.customer }}</strong></p>
          <p>Order ID: <strong>{{ detail.orderid }}</strong></p>
          <p>Price: <strong>{{ convertPrice }} THB</strong></p>
        </div>
      </div>
    </b-container>
    <form id="loading">
      <input class="form-control" type="hidden" name="id">
      <input class="form-control" type="hidden" name="orderid">
      <input class="form-control" type="hidden" name="currency">
      <input class="form-control" type="hidden" name="amount">
      <input class="form-control" type="hidden" name="status">
      <input class="form-control" type="hidden" name="payment">
    </form>
  </div>
</template>

<script>
export default {
  name: "McpaymentReturn",
  beforeMount() {
    const query = this.$route.query
    if (query.token) {
      this.token = query.token
      this.get_token()
    } else {
      this.$swal('Not have token', '', 'error')
    }
  },
  data() {
    return {
      loading: true,
      token: '',
      data: {
        id: '',
        orderid: '',
        currency: '',
        amount: '',
        status: '',
        payment: ''
      }
    }
  },
  methods: {
    async get_token() {
      this.loading = true
      try {
        const result = await this.axios.post('/paymentpage/mcpayment/return', {token: this.token})
        if (result.data) {
          const data = result.data
          this.data.id = data.id
          this.data.orderid = data.orderid
          this.data.currency = data.currency
          this.data.amount = data.amount
          this.data.status = data.status
          this.data.payment = data.payment
          if (data.return_url) {
            this.submit_from(result.data)
          }
        }
      } catch (e) {
        this.$swal('Error', e.response && e.response.data ? e.response.data.message : '', 'error')
      }
      this.loading = false
    },
    submit_from(data) {
      const form = document.getElementById('loading')
      form.action = data.return_url
      form.method = 'POST'
      form.elements['id'].value = data.id
      form.elements['orderid'].value = data.orderid
      form.elements['currency'].value = data.currency
      form.elements['amount'].value = data.amount
      form.elements['status'].value = data.status
      form.elements['payment'].value = data.payment
      form.submit()
    }
  }
}
</script>

<style scoped>
.loader {
  margin: auto;
  text-align: center;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
